/* eslint-disable @typescript-eslint/no-non-null-assertion */
import AuthenticationService from "@/services/authentication-service";

class BaseService {
  protected readonly baseApiUrl = process.env.VUE_APP_BASE_API_URL;

  /**
   * The fetch function sends HTTP requests with added functionality for handling authentication, error responses,
   * and different content types.
   * @param {RequestInfo} input - The `input` parameter in the `fetch` function represents the resource (URL) that you want to fetch or
   * make a request to. It can be a URL string or a `Request` object.
   * @param {RequestInit} [init] - The `init` parameter in the `fetch` function is an optional parameter of type `RequestInit`.
   * It is an object that allows you to control various settings for the HTTP request being made, such as the request method, headers,
   * body, mode, cache, credentials, and more. It provides
   * @param {string} [token] - The `token` parameter in the `fetch` function is used to provide an authentication token for making
   * authorized requests. If a `token` is not provided when calling the `fetch` function, it will attempt to retrieve a JWT token using the
   * `AuthenticationService.getJWTToken()` method. If the token is not available, the user will be redirected to the login page.
   * @returns The `fetch` function returns a Promise that resolves to a value of type `T`. The function performs a series of operations
   * including making a fetch request with optional headers, handling different HTTP status codes, checking for maintenance mode, and
   * processing different content types. The final resolved value of the Promise depends on the specific logic executed based on the
   * response from the fetch request.
   */
  fetch<T>(input: RequestInfo, init?: RequestInit, token?: string): Promise<T> {
    if (!token) {
      token = AuthenticationService.getJWTToken();
    }
    return fetch(input, {
      ...init,
      ...(token && {
        headers: {
          ...init?.headers,
          Authorization: `Bearer ${token}`,
        },
      }),
    })
      .then((response) => {
        switch (response.status) {
          case 0:
            // Handle network errors
            window.location.href = "/maintenance";
            break;
          case 401:
          case 403:
            return AuthenticationService.authenticate().then((token) =>
              this.fetch(input, init, token)
            );
          case 404:
            // TODO: Needed to break out of the Vue ecosystem to make this work, maybe there is a Vue way to do this
            window.location.href = "/not-found";
            break;
          case 400:
          case 500:
            response.text().then((result) => {
              console.error(result);
            });
            break;
          case 204:
            console.log("No data was returned");
            break;
          default:
            break;
        }

        // response.ok = status is in the range 200-299
        if (!response.ok || response.status === 204) {
          return Promise.reject(response.status);
        }

        const inMaintenanceMode = response.headers.get("x-maintenance-mode");

        if (inMaintenanceMode && inMaintenanceMode === "true") {
          window.location.href = "/maintenance";
        }

        const contentType = response.headers.get("content-type");

        switch (contentType) {
          case "application/json; charset=utf-8":
            return response.json();
          case "application/vnd.ms-excel":
          case "application/pdf":
          case "text/csv; charset=utf-8": {
            const filename = this.getFileName(response);
            response.blob().then((blob: Blob) => {
              try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                link.click();
                URL.revokeObjectURL(link.href);

                return Promise.resolve(true);
              } catch (e) {
                console.error("Error: ", e);
                return Promise.resolve(false);
              }
            });
            break;
          }
          default:
            break;
        }
      })
      .catch((e: Error) => {
        console.error("Error: ", e);
        // This redirect is intended to handle network errors, ie. the server is down
        // A TypeError in JavaScript is thrown when an operation could not be performed,
        // typically when a value is not of the expected type.
        // I have not found a better way to handle network errors.
        // When using the Fetch API, network errors like net::ERR_CONNECTION_REFUSED are considered operational errors,
        // not network errors, so they do not reject the promise returned by fetch
        // if (e instanceof TypeError) {
        //   window.location.href = "/maintenance";
        // }
      });
  }

  /**
   * The function `getFileName` extracts the filename from the content-disposition header of a response.
   * @param {Response} response - The `response` parameter in the `getFileName` function is of type `Response`, which represents a
   * response object from a network request in the `fetch` method above. This response object typically contains information such as
   * headers, status, body content, etc.
   * @returns the filename extracted from the "content-disposition" header of the response.
   */
  private getFileName(response: Response) {
    const disposition = response.headers.get("content-disposition");
    const match = disposition!.match(/filename\s*=\s*(.+);/i);
    const filename = match![1];
    return filename;
  }
}

export default BaseService;
